import { render, staticRenderFns } from "./TeamReminder.vue?vue&type=template&id=2a46d0d2&scoped=true&"
import script from "./TeamReminder.vue?vue&type=script&lang=js&"
export * from "./TeamReminder.vue?vue&type=script&lang=js&"
import style0 from "./TeamReminder.vue?vue&type=style&index=0&id=2a46d0d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.5_e6f9754d45536372aa6e5c6b320144db/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a46d0d2",
  null
  
)

export default component.exports