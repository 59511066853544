import { render, staticRenderFns } from "./Attributions.vue?vue&type=template&id=cf73d82a&scoped=true&"
import script from "./Attributions.vue?vue&type=script&lang=js&"
export * from "./Attributions.vue?vue&type=script&lang=js&"
import style0 from "./Attributions.vue?vue&type=style&index=0&id=cf73d82a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.5_e6f9754d45536372aa6e5c6b320144db/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf73d82a",
  null
  
)

export default component.exports