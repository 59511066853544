var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.teamClass,attrs:{"id":"GameBoard"}},[_c('div',{staticClass:"team-container",attrs:{"id":"other-team-answers"}},[_c('div',{staticClass:"answer-container maximize"},_vm._l((8),function(answerIndex){return _c('div',{key:(_vm.otherTeamID + "-answer-container-" + answerIndex),class:[
					"answer",
					_vm.answers[("team_" + (3 - _vm.$store.state.team))][answerIndex-1].toLowerCase().match(_vm.getObject)
						? "correct": ""
				]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.answers[("team_" + (3 - _vm.$store.state.team))][answerIndex-1]),expression:"answers[`team_${3 - $store.state.team}`][answerIndex-1]"}],staticClass:"other-team-answer",attrs:{"type":"text","id":(_vm.otherTeamID + "-answer-" + answerIndex),"disabled":""},domProps:{"value":(_vm.answers[("team_" + (3 - _vm.$store.state.team))][answerIndex-1])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.answers[("team_" + (3 - _vm.$store.state.team))], answerIndex-1, $event.target.value)}}}),(_vm.$store.state[("team_" + (3 - _vm.$store.state.team))].eyes[answerIndex] > 0)?_c('span',{staticClass:"other-team eye-container"},[(_vm.$store.state[("team_" + (3 - _vm.$store.state.team))].eyes[answerIndex] > 1)?_c('span',{staticClass:"eye-multiplier"},[_vm._v(" "+_vm._s(_vm.$store.state[("team_" + (3 - _vm.$store.state.team))].eyes[answerIndex])+" x ")]):_vm._e(),_c('img',{staticClass:"eye",attrs:{"src":("/assets/" + (_vm.$store.state.eye_icon)),"alt":"reveal another letter"}})]):_vm._e()])}),0)]),_c('div',{staticClass:"team-container",attrs:{"id":"team-answers"}},[_c('div',{staticClass:"answer-container maximize"},_vm._l((8),function(answerIndex){return _c('div',{key:(_vm.teamID + "-answer-container-" + answerIndex),class:[
					"answer",
					_vm.answers[("team_" + (_vm.$store.state.team))][answerIndex-1].toLowerCase().match(_vm.getObject)
						? "correct": ""
				]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.answers[("team_" + (_vm.$store.state.team))][answerIndex-1]),expression:"answers[`team_${$store.state.team}`][answerIndex-1]"}],staticClass:"team-answer",attrs:{"type":"text","id":(_vm.teamID + "-answer-" + answerIndex)},domProps:{"value":(_vm.answers[("team_" + (_vm.$store.state.team))][answerIndex-1])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.answers[("team_" + (_vm.$store.state.team))], answerIndex-1, $event.target.value)},function($event){$event.stopPropagation();return _vm.answerInputHandler(answerIndex)}]}}),(_vm.$store.state[("team_" + (_vm.$store.state.team))].eyes[answerIndex] > 0)?_c('span',{staticClass:"team eye-container"},[_c('img',{staticClass:"eye",attrs:{"src":("/assets/" + (_vm.$store.state.eye_icon)),"alt":"reveal another letter"}}),(_vm.$store.state[("team_" + (_vm.$store.state.team))].eyes[answerIndex] > 1)?_c('span',{staticClass:"eye-multiplier"},[_vm._v(" x "+_vm._s(_vm.$store.state[("team_" + (_vm.$store.state.team))].eyes[answerIndex])+" ")]):_vm._e()]):_vm._e()])}),0)]),_c('button',{staticClass:"clickable",attrs:{"id":"past-questions-toggle"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.visible = !_vm.visible}}},[_vm._v(" "+_vm._s(_vm.visible ? "Hide" : "Show")+" Past Questions ")]),_vm._m(0),_c('transition',{attrs:{"name":"expand-from-left"}},[(_vm.visible)?_c('past-questions',{on:{"error":function($event){return _vm.$emit("error", $event)}}}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"http://phantominkgame.com/","target":"_blank","id":"game-logo-link"}},[_c('img',{attrs:{"id":"game-logo","src":"/assets/phantom_ink_logo.png","alt":"Phantom Ink Logo","rel":"noopener"}})])}]

export { render, staticRenderFns }